
/* ------------------------------------------------------------------------------
	Slider Images URL
-------------------------------------------------------------------------------*/

#first-slider .slide1 {
    background-image: url('../img/slides/1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
#first-slider .slide2 {
  background-image: url('../img/slides/2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position-x: -402px; */
}
#first-slider .slide3 {
  background-image: url('../img/slides/3.jpg');
      background-size: cover;
    background-repeat: no-repeat;
}

/* ------------------------------------------------------------------------------
	Typography
-------------------------------------------------------------------------------*/

p {
	font-size: 14px;
	line-height: 22.4px;
	color: #6c7279;
}
h1 {
	font-size: 65px;
	color: #2d3033;
}
h2 {
	font-size: 40px;
	color: #2d3033;
}
h3 {
	font-size: 40px;
	color: #2d3033;
	font-weight: 300;
}
h4 {
	font-size: 22px;
	color: #ac6ed4;
	font-weight: 500;
}
h5 {
	font-size: 14px;
	color: #2d3033;
	text-transform: uppercase;
	font-weight: 700;
}
h1, h2, h3, h4, h5, h6{
	font-family: 'Ubuntu', sans-serif;
}
.fix-hed{

position: fixed !important;

background: #232323 !important;
}
#detailsPage{
	padding-top:140px;
}
.btn {
	background-color: transparent;
	color: #ac6ed4;
	font-size: 16px;
	font-weight: 600;
	border: 0;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	display: inline-block;
	text-transform: uppercase;
	border: 1px solid #ac6ed4;
}
.btn:hover, .btn:focus {
	background-color: #ac6ed4;
	color: #fff;
}
.btn-large {
	padding: 15px 40px;
}
#map-overlay {
    height:400px;
    margin-top:-405px;
    background-color: #232323;
    padding:40px;
    color: #848484;
}
/* ------------------------------------------------------------------------------
	Global Styles
-------------------------------------------------------------------------------*/
a {
	color: #ac6ed4;
}
a:hover, a:focus {
	text-decoration: none;
	-moz-transition: background-color, color, 0.3s;
	-o-transition: background-color, color, 0.3s;
	-webkit-transition: background-color, color, 0.3s;
	transition: background-color, color, 0.3s;
}
a.morebtn {
    border: 1px solid #ac6ed4;
    padding: 6px 18px;
    margin-top: 15px;
    display: inline-block;
}
a.morebtn:hover {
    color: #fff;
    background: #ac6ed4;
}
body {
	font-family: "Open Sans", Arial, sans-serif;
	font-weight: 400;
	color: #6c7279;
}
ul, ol {
	margin: 0;
	padding: 0;
}
ul li {
	list-style: none;
}
.section {
	padding: 60px 0;
	margin-bottom: 50px;
}
.no-padding {
	padding: 0;
}
.no-gutter [class*=col-] {
	padding-right: 0;
	padding-left: 0;
}
.space {
	margin-top: 60px;
}
/* ------------------------------------------------------------------------------
	Header
-------------------------------------------------------------------------------*/
#header {
	position: fixed;
	width: 100%;
	z-index: 999;
	background: #333333;
	border-top: 8px #363942 solid;
	/* border-bottom: 8px #363942 solid; */
}
#header .header-content {
	margin: 0 auto;
	max-width: 1170px;
	padding: 12px 0;
	width: 100%;
	-moz-transition: padding 0.3s;
	-o-transition: padding 0.3s;
	-webkit-transition: padding 0.3s;
	transition: padding 0.3s;
}
#header .logo {
	float: left;
	font-size: 55px;
	color: #141414;
	text-decoration:none;
	letter-spacing: 2px;
	/* margin-top: -10px; */
	font-family: 'Great Vibes', cursive;
	font-weight: 700;
	text-transform: lowercase;
	line-height: 45px;
	position: relative;
	top: 5px;
}
#header .logo span {
    font-weight: 400;
    font-family: monospace;
    letter-spacing: 0px;
    font-size: 22px;
    margin-left: -4px;
    color: #fff;
    top: -1px;
    position: relative;
}
#header.fixed {
	background-color: #333333;
}
#header.fixed a {
	/* color: #fff; */
}
#header.fixed .header-content {
	border-bottom: 0;
	padding: 12px 0;
}
#header.fixed .nav-toggle {
	top: 14px;
	color: #ac6ed4;
}
.navigation.open {
	opacity: 0.9;
	visibility: visible;
	-moz-transition: opacity 0.5s;
	-o-transition: opacity 0.5s;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}
.navigation {
	float: right;
	margin-top: 15px;
}
.navigation li {
	display: inline-block;
}
.navigation a {
	color: #f9f2ef;
	font-size: 14px;
	/* font-weight: 700; */
	margin-left: 40px;
	text-transform: uppercase;
}
.navigation a:hover, .navigation a.active {
	color: #ac6ed4;
}
.nav-toggle {
	display: none;
	height: 44px;
	overflow: hidden;
	position: fixed;
	right: 5%;
	text-indent: 100%;
	top: 20px;
	white-space: nowrap;
	width: 44px;
	z-index: 99999;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	/* background: rgba(255, 255, 255, 0.56); */
	color: #fff !important;
}
.nav-toggle:before, .nav-toggle:after {
	border-radius: 50%;
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-moz-backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	-webkit-transition-property: -webkit-transform;
	transition-property: transform;
}
.nav-toggle:before {
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-webkit-transform: scale(1);
	transform: scale(1);
	-moz-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	color: #ffffff;
}
.nav-toggle:after {
	background-color: transparent;
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-webkit-transform: scale(0);
	transform: scale(0);
	-moz-transition-duration: 0s;
	-o-transition-duration: 0s;
	-webkit-transition-duration: 0s;
	transition-duration: 0s;
	color: #ac6ed4;
}
.nav-toggle span {
	bottom: auto;
	display: inline-block;
	height: 3px;
	left: 50%;
	position: absolute;
	right: auto;
	top: 50%;
	width: 18px;
	z-index: 10;
	-moz-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}
.nav-toggle span:before, .nav-toggle span:after {
	background-color: #e2e2e2;
	content: "";
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-moz-backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-moz-transition: -moz-transform 0.3s;
	-o-transition: -o-transform 0.3s;
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
}
.nav-toggle span:before {
	-moz-transform: translateY(-6px) rotate(0deg);
	-ms-transform: translateY(-6px) rotate(0deg);
	-webkit-transform: translateY(-6px) rotate(0deg);
	transform: translateY(-6px) rotate(0deg);
}
.nav-toggle span:after {
	-moz-transform: translateY(6px) rotate(0deg);
	-ms-transform: translateY(6px) rotate(0deg);
	-webkit-transform: translateY(6px) rotate(0deg);
	transform: translateY(6px) rotate(0deg);
}
.nav-toggle.close-nav:before {
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-webkit-transform: scale(0);
	transform: scale(0);
}
.nav-toggle.close-nav:after {
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-webkit-transform: scale(1);
	transform: scale(1);
}
.nav-toggle.close-nav span {
	background-color: rgba(255, 255, 255, 0);
}
.nav-toggle.close-nav span:before, .nav-toggle.close-nav span:after {
	background-color: #fff;
}
.nav-toggle.close-nav span:before {
	-moz-transform: translateY(0) rotate(45deg);
	-ms-transform: translateY(0) rotate(45deg);
	-webkit-transform: translateY(0) rotate(45deg);
	transform: translateY(0) rotate(45deg);
}
.nav-toggle.close-nav span:after {
	-moz-transform: translateY(0) rotate(-45deg);
	-ms-transform: translateY(0) rotate(-45deg);
	-webkit-transform: translateY(0) rotate(-45deg);
	transform: translateY(0) rotate(-45deg);
}
/* ------------------------------------------------------------------------------
	Banner
-------------------------------------------------------------------------------*/

#first-slider .main-container {
  padding: 0;
}


#first-slider .slide1 h3, #first-slider .slide2 h3, #first-slider .slide3 h3, #first-slider .slide4 h3{
    color: #fff;
    font-size: 70px;
}

#first-slider .slide1 h4,#first-slider .slide2 h4,#first-slider .slide3 h4,#first-slider .slide4 h4{
    color: #fff;
    font-size: 20px;
    /* text-transform: uppercase; */
    font-weight: 500;
    line-height: 32px;
    font-family: sans-serif;
}
#first-slider .slide1 .text-left ,#first-slider .slide3 .text-left{
    padding-left: 40px;
}


#first-slider .carousel-indicators {
  bottom: 30px;
}
#first-slider .carousel-control.right,
#first-slider .carousel-control.left {
  background-image: none;
}
#first-slider .carousel .item {
  min-height: 720px;
  height: 100%;
  width:100%;
}

.carousel-inner .item .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}


#first-slider h3{
  animation-delay: 1s;
  text-align: center;
}
#first-slider h4 {
  animation-delay: 2s;
  text-align: center;
}
#first-slider h2 {
  animation-delay: 3s;
}


#first-slider .carousel-control {
    width: 6%;
        text-shadow: none;
}


#first-slider h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: bold;
}

#first-slider .p {
  padding-top: 125px;
  text-align: center;
}

#first-slider .p a {
  text-decoration: underline;
}
#first-slider .carousel-indicators li {
    width: 14px;
    height: 14px;
    background-color: rgba(255,255,255,.4);
  border:none;
}
#first-slider .carousel-indicators .active{
    width: 16px;
    height: 16px;
    background-color: #fff;
  border:none;
}


.carousel-fade .carousel-inner .item {
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0;
}
.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.carousel-fade .carousel-control {
  z-index: 2;
}

.carousel-control .fa-angle-right, .carousel-control .fa-angle-left {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
}
.carousel-control .fa-angle-left{
    left: 50%;
    width: 38px;
    height: 38px;
    margin-top: -15px;
    font-size: 30px;
    color: #fff;
    border: 3px solid #ffffff;
    -webkit-border-radius: 23px;
    -moz-border-radius: 23px;
    border-radius: 53px;
}
.carousel-control .fa-angle-right{
    right: 50%;
    width: 38px;
    height: 38px;
    margin-top: -15px;
    font-size: 30px;
    color: #fff;
    border: 3px solid #ffffff;
    -webkit-border-radius: 23px;
    -moz-border-radius: 23px;
    border-radius: 53px;
}
.carousel-control {
    opacity: 1;
    filter: alpha(opacity=100);
}



/* ==========================================================================
	$intro
========================================================================== */
.intro {
	background-color: #333333;
	/* color: #000; */
}
.intro h3 {
	color: #fff;
	margin-bottom: 20px;
	font-size: 38px;
}
.intro p {
	color: #fff;
	font-weight: 400;
}
/* -----------------------------------------------------------------------------
Common Styles
------------------------------------------------------------------------------- */
.section-header{
text-align: center;
padding-bottom: 30px;
}
.section-header h2{

font-size: 48px;
}
.section-header p{

}


/* ------------------------------------------------------------------------------
	 Serives
-------------------------------------------------------------------------------*/
.service-section {
	padding-top: 100px;
}
.services {
	margin-bottom: 50px;
}
.services-content {
	padding: 30px 30px 20px;
	margin-top: 7px;
	background: #ffffff;
	border: 1px solid #fff;
	border: 4px solid #ac6ed4;
}
.services .icon {
	color: #ffffff;
	font-size: 38px;
	padding-left: 15px;
	/* float: left; */
	background: #ac6ed4;
	padding: 20px;
	width: 90px;
	margin: 0 auto;
	display: inline-block;
}
/* ------------------------------------------------------------------------------
	 gallery
-------------------------------------------------------------------------------*/
.work {
	-moz-box-shadow: 0 0 0 1px #fff;
	-webkit-box-shadow: 0 0 0 1px #fff;
	box-shadow: 0 0 0 1px #fff;
	overflow: hidden;
	position: relative;
	background: #000;
}
.work img {
	width: 100%;
	height: 100%;
	opacity: .6;
}
.work .overlay {
	background: rgba(56, 56, 56, 0.58);
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	width: 100%;
	-moz-transition: opacity, 0.3s;
	-o-transition: opacity, 0.3s;
	-webkit-transition: opacity, 0.3s;
	transition: opacity, 0.3s;
}
.work .overlay-caption {
	position: absolute;
	text-align: center;
	top: 50%;
	width: 100%;
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
.work h5, .work p, .work img {
	-moz-transition: all, 0.5s;
	-o-transition: all, 0.5s;
	-webkit-transition: all, 0.5s;
	transition: all, 0.5s;
}
.work h5, .work p {
	color: #fff;
	margin: 0;
	opacity: 0;
}
.work span {
	font-size: 45px;
}
.work h5 {
	margin-bottom: 5px;
	-moz-transform: translate3d(0, -200%, 0);
	-ms-transform: translate3d(0, -200%, 0);
	-webkit-transform: translate3d(0, -200%, 0);
	transform: translate3d(0, -200%, 0);
}
.work p {
	-moz-transform: translate3d(0, 200%, 0);
	-ms-transform: translate3d(0, 200%, 0);
	-webkit-transform: translate3d(0, 200%, 0);
	transform: translate3d(0, 200%, 0);
}
.work-box:hover img {
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
}
.work-box:hover .overlay {
	opacity: 1;
}
.work-box:hover .overlay h5, .work-box:hover .overlay p {
	opacity: 1;
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
.services-content h5 {
    margin: 20px;
}
.video-section{padding: 88px 0 20px;}
address {
    line-height: 1.42857143;
    color: #b5b5b5 !important;
}
/* ---------------------------------------------------------------------------
About
------------------------------------------------------------------------------*/
/*	CONTENT BLOCK 3-10
/*----------------------------------------------------*/

.content-3-10 {
	padding: 0px;
	position: relative;
}

.content-3-10 .image-container {
	position: absolute;
	height: 300px;
	padding: 0px;
	top: 0px;
}

.content-3-10 .image-container .background-image-holder {
	background: url('../img/salon/salon1.jpg') no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 100%;
}

.content-3-10 .content {
	margin-top: 300px;
	padding: 40px;
}

.content-3-10 h3 {
	margin-bottom: 34px;
}

.content-3-10 p {
	margin-bottom: 20px;
}

.img-middle{
	display: block;
	border: 1px solid #d8d8d8;
	padding: 25px;
	margin: 20px auto 25px;
	width: 100%;
}
.align-center {
    text-align: center;
}
/*	CONTENT BLOCK 3-10 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

	.content-3-10 .image-container {
		height: 100%;
	}

	.content-3-10 .content {
		margin-top: 0px;
		padding: 80px 15px 80px 80px;
		background: #eaeaea;
	}

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {



}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {



}

/* ------------------------------------------------------------------------------
	 package
-------------------------------------------------------------------------------*/
.packageList {
	margin-bottom: 50px;
}
h5 {
    font-size: 1.9em;
    font-weight: 500;
    margin: 0.3em 0;
    text-transform: capitalize;
}
ul.list-default {
    list-style-type: none;
    padding: 0;
}
ul.list-default li {
    padding: 0.5em 0 0.5em 2em;
    position: relative;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.package {
	border: 1px solid rgba(0, 0, 0, 0.1) !important;
	position: relative;
	border-style: none none solid none !important;
	padding: 1em 0 0.5em;
}
.package:hover {
	background-color: #fbfbfb;
}
.package:hover h5 {
	color: #ff67ab;
}
.package:hover .price {
	background-color: #ff348f;
}
.package ul.list-default li {
	border: none !important;
	padding: 4px 0;
}
.package ul.list-default li:before {
	top: 0.3em !important;
}
.price {
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    position: absolute;
    background-color: #ff67ab;
    font-size: 1.35em;
    font-weight: 500;
    color: #fff;
    padding: 0.5em;
    margin: -1px -1px -1px 0;
}
.package .price {
	top: 1em;
	right: 1em;
	bottom: auto;
	font-size: 1.5em;
	padding: 0.7em;
}
.package .price small {
	font-size: 0.7em;
	margin-right: 3px;
}
/* ------------------------------------------------------------------------------
	 Teams
-------------------------------------------------------------------------------*/

.teams{
  text-align: -webkit-center;
}

.person {
	max-width: 270px;
	text-align: center;
}
.person img {
	width: 150px;
	height: 150px;
	margin: auto;
}
.person-content {
	margin-top: 20px;
}
.person h4 {
	font-weight: 400;
}
.person h5 {
	color: #0385dc;
	font-size: 13px;
	font-weight: 400;
	margin-bottom: 20px;
}
.social-icons, .footer .footer-share {
	margin-top: 20px;
}
.social-icons li, .footer .footer-share li {
	display: inline-block;
	float: none;
}
.social-icons a, .footer .footer-share a {
	border: 1px solid #3496d8;
	color: #3496d8;
	display: block;
	font-size: 14px;
	height: 32px;
	line-height: 32px;
	margin-right: 5px;
	text-align: center;
	width: 32px;
}
.social-icons a:hover {
	background-color: #00aeda;
	border-color: #01a9d4;
	color: #fff;
}
/* ------------------------------------------------------------------------------
	 Testimonials
-------------------------------------------------------------------------------*/
.testimonials {
	background-color: #333333;
	position: relative;
	text-align: center;
}
.testimonials blockquote {
	border: 0;
	margin: 0;
	padding: 100px 15%;
}
.testimonials h1 {
	color: #fff;
	font-size: 16px;
	font-weight: 300;
}
.testimonials p {
	color: #fff;
	display: block;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	margin-top: 30px;
	text-transform: uppercase;
	margin: 30px 15%;
}
.flex-control-nav {
	margin-top: 2%;
	bottom: none!important;
	position: relative!important;
	right: 0;
	text-align: center;
	width: 100%!important;
	z-index: 100;
}
/* ------------------------------------------------------------------------------
	Contact form
-------------------------------------------------------------------------------*/
.conForm {
	text-align: center;
}
.conForm h5 {
	font-size: 30px;
}
.conForm p {
	text-align: center;
	margin: 7%;
}
.conForm input {
	color: #797979;
	padding: 15px 30px;
	border: none;
	margin-right: 3%;
	margin-bottom: 30px;
	outline: none;
	font-style: normal;
	border: #e0e0e0 1px solid;
	font-size: 15px;
}
.conForm input.noMarr {
	margin-right: 0px;
}
.conForm textarea {
	color: #797979;
	padding: 15px 30px;
	margin-bottom: 18px;
	outline: none;
	height: 150px;
	font-style: normal;
	resize: none;
	font-size: 15px;
	border: none;
	border: #e0e0e0 1px solid;
}
.conForm .submitBnt {
	background: #333333;
	color: #fff;
	margin-top: 30px;
	padding:15px 30px 15px 30px;
	font-size: 13px;
	font-weight: 600;
	letter-spacing: 5px;
	border: 0;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	display: inline-block;
	text-transform: uppercase;
}
.conForm .submitBnt:hover {
	background: #E5E5E5;
	color: #e8206b;
}
#success_page{
	color: #00bdbd;
	font-weight: 500;
}
.error_message{
	color: #ff675f;
	padding-bottom: 15px;
	font-weight: 500;
}
#success_page h3{
	font-size:17px;
	color:#5ed07b;
	font-weight: 700;
}

/* ------------------------------------------------------------------------------
	 Footer
-------------------------------------------------------------------------------*/
.footer {
	text-align: left;
}
.footer-top {
	background-color: #181818;
	padding-top: 50px;
}
.footer-bottom {
	background-color: #313454;
	padding: 20px 0;
}
.footer .footer-col {
	margin-bottom: 80px;
}
.footer h5 {
	color: #fff;
}
.footer h5 {
	margin-bottom: 20px;
}
.footer p {
	color: rgba(255, 255, 255, 0.25);
}
.footer a {
	color: rgba(255, 255, 255, 0.50);
}
.footer a:hover {
	color: #e84545;
}
.footer .footer-share {
	margin-top: 0;
}
.footer .footer-share li {
	display: inline-block;
	float: none;
}
.footer .footer-share a {
	border: none;
	font-size: 21px;
	color: rgba(255, 255, 255, 0.25);
}
.footer .fa-heart {
	color: #e84545;
	font-size: 11px;
	margin: 0 2px;
}

#footer-nav{
  position: relative;
  bottom: -150px;
  height: 35px;
}

.opening-time { background: url('../img/lanes.png') no-repeat center top; width: 215px; padding-top: 47px; color: #fff; }
.opening-time .time-table {background-color: #303030;-webkit-border-radius: 2px;-moz-border-radius: 2px;border-radius: 2px;padding-bottom: 8px;}
.opening-time .time-table h3 { font-size: 18px; margin: 0; text-align: center; color: #fff; line-height: 2.2; }
.opening-time .time-table > .inner-bg { margin: 0 8px; padding: 0 3px; background: url('../img/opening-time.gif') repeat; -webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px; border-bottom: 1px solid #444444; -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.6); -moz-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.6); box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.6); }
.opening-time .time-table > .additional-info { padding: 2px 8px 0; text-align: center; }
.opening-time .week-day { padding: 7px 10px; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; margin: 0; }
.opening-time .week-day.light-bg { background-image: url('../img/opening-time-light.gif'); }
.opening-time .week-day dt { width: 50%; float: left; }
.opening-time .week-day dd { text-align: right; }
.opening-time .week-day.closed dt, .opening-time .week-day.closed dd { color: #676767; }
.opening-time .week-day.today dt { color: #ac6ed4; }

.opening-time-2 { background: url('../img/lanes.png') no-repeat center top; width: 100%; padding-top: 47px; color: #fff; }
.opening-time-2 .time-table {background-color: #4e4d4d;-webkit-border-radius: 2px;-moz-border-radius: 2px;border-radius: 2px;padding-bottom: 8px;}
.opening-time-2 .time-table h3 { font-size: 18px; margin: 0; text-align: center; color: #fff; line-height: 2.2; }
.opening-time-2 .time-table > .inner-bg { margin: 0 8px; padding: 0 3px; background: url('../img/opening-time.gif') repeat; -webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px; border-bottom: 1px solid #444444; -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.6); -moz-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.6); box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.6); }
.opening-time-2 .time-table > .additional-info { padding: 2px 8px 0; text-align: center; }
.opening-time-2 .week-day { padding: 7px 10px; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; margin: 0; }
.opening-time-2 .week-day.light-bg { background-image: url('../img/opening-time-light.gif'); }
.opening-time-2 .week-day dt { width: 50%; float: left; }
.opening-time-2 .week-day dd { text-align: right; }
.opening-time-2 .week-day.closed dt, .opening-time .week-day.closed dd { color: #676767; }

#dopamin{
  position: fixed;
  bottom: 5px;
  right: 1%;
  font-size: xx-small;
}


/* = Sprites = */
.icons-sprite, .icons-arrow-left-white, .icons-arrow-left, .icons-arrow-right-white, .icons-arrow-right, .icons-calendar, .icons-child, .icons-divider-1, .icons-divider-2, .icons-divider-3, .icons-divider-4, .icons-divider-5, .icons-divider-6, .icons-divider-7, .icons-divider-8, .icons-divider-9, .icons-double-line, .icons-her, .icons-him, .icons-minus, .icons-nested, .icons-ornament-left, .icons-ornament-right, .icons-pagination-next, .icons-pagination-prev, .icons-plus, .icons-scissors, .icons-slider-nav-left, .icons-slider-nav-right, .icons-to-top-arrow, .icons-zoom, .accordion-heading a .icon, .accordion-heading.open a .icon { background-image: url('../img/icon-set.png'); background-repeat: no-repeat; }

.icons-arrow-left-white { background-position: 0 0; height: 13px; width: 13px; }

.icons-arrow-left { background-position: 0 -13px; height: 13px; width: 13px; }

.icons-arrow-right-white { background-position: 0 -26px; height: 13px; width: 13px; }

.icons-arrow-right { background-position: 0 -39px; height: 13px; width: 13px; }

.icons-calendar { background-position: 0 -52px; height: 13px; width: 13px; }

.icons-child { background-position: 0 -65px; height: 14px; width: 14px; }

.icons-divider-1 { background-position: 0 -79px; height: 33px; width: 33px; }

.icons-divider-2 { background-position: 0 -112px; height: 33px; width: 33px; }

.icons-divider-3 { background-position: 0 -145px; height: 33px; width: 33px; }

.icons-divider-4 { background-position: 0 -178px; height: 33px; width: 33px; }

.icons-divider-5 { background-position: 0 -211px; height: 33px; width: 33px; }

.icons-divider-6 { background-position: 0 -244px; height: 33px; width: 33px; }

.icons-divider-7 { background-position: 0 -277px; height: 33px; width: 33px; }

.icons-divider-8 { background-position: 0 -310px; height: 33px; width: 33px; }

.icons-divider-9 { background-position: 0 -343px; height: 33px; width: 33px; }

.icons-double-line { background-position: 0 -376px; height: 6px; width: 114px; }

.icons-her { background-position: 0 -382px; height: 16px; width: 14px; }

.icons-him { background-position: 0 -398px; height: 16px; width: 14px; }

.icons-minus { background-position: 0 -414px; height: 23px; width: 23px; }

.icons-nested { background-position: 0 -437px; height: 8px; width: 25px; }

.icons-ornament-left { background-position: 0 -445px; height: 9px; width: 33px; }

.icons-ornament-right { background-position: 0 -454px; height: 9px; width: 33px; }

.icons-pagination-next { background-position: 0 -463px; height: 10px; width: 11px; }

.icons-pagination-prev { background-position: 0 -473px; height: 10px; width: 11px; }

.icons-plus { background-position: 0 -483px; height: 23px; width: 23px; }

.icons-scissors { background-position: 0 -506px; height: 33px; width: 33px; }

.icons-slider-nav-left { background-position: 0 -539px; height: 53px; width: 53px; }

.icons-slider-nav-right { background-position: 0 -592px; height: 53px; width: 53px; }

.icons-to-top-arrow { background-position: 0 -645px; height: 11px; width: 15px; }

.icons-zoom { background-position: 0 -656px; height: 16px; width: 16px; }

.icon { display: inline-block; vertical-align: middle; vertical-align: auto; zoom: 1; display: inline; }


/* = Social icons = */
.inactive-sprite, .social-icon.twitter > .inactive, .social-icon.facebook > .inactive, .social-icon.youtube > .inactive, .social-icon.rss > .inactive, .social-icon.stumble > .inactive, .social-icon.skype > .inactive, .social-icon.tumblr > .inactive, .social-icon.pinterest > .inactive, .social-icon.linkedin > .inactive, .social-icon.google > .inactive, .social-icon.vimeo > .inactive, .social-icon.mail > .inactive, .social-icon.favorites > .inactive, .social-icon.flickr > .inactive, .social-icon.instagram > .inactive, .social-icon.yelp > .inactive { background-image: url('../img/social-icons/sm-inactive.png'); background-repeat: no-repeat; }

.active-sprite, .social-icon.twitter, .social-icon.facebook, .social-icon.youtube, .social-icon.rss, .social-icon.stumble, .social-icon.skype, .social-icon.tumblr, .social-icon.pinterest, .social-icon.linkedin, .social-icon.google, .social-icon.vimeo, .social-icon.mail, .social-icon.favorites, .social-icon.flickr, .social-icon.instagram, .social-icon.yelp { background-image: url('../img/social-icons/sm-active.png'); background-repeat: no-repeat; }

.social-icons { position: absolute; bottom: 10px; right: 0; z-index: 99; display: flex }

.social-icon { display: inline-block; -webkit-transition: background 1s ease-in-out; -moz-transition: background 1s ease-in-out; -o-transition: background 1s ease-in-out; transition: background 1s ease-in-out; }
.social-icon > .inactive { display: inline-block; -webkit-transition: opacity 0.3s ease-in-out; -moz-transition: opacity 0.3s ease-in-out; -o-transition: opacity 0.3s ease-in-out; transition: opacity 0.3s ease-in-out; }
.social-icon:hover > .inactive { opacity: 0; filter: alpha(opacity=0); }
.social-icon.twitter { background-position: 0 -384px; height: 32px; width: 32px; }
.social-icon.twitter > .inactive { background-position: 0 -384px; height: 32px; width: 32px; }
.social-icon.facebook { background-position: 0 0; height: 32px; width: 32px; }
.social-icon.facebook > .inactive {background-position: 0 0;height: 32px;width: 32px;}
.social-icon.youtube { background-position: 0 -480px; height: 32px; width: 32px; }
.social-icon.youtube > .inactive { background-position: 0 -480px; height: 32px; width: 32px; }
.social-icon.rss { background-position: 0 -256px; height: 32px; width: 32px; }
.social-icon.rss > .inactive { background-position: 0 -256px; height: 32px; width: 32px; }
.social-icon.stumble { background-position: 0 -320px; height: 32px; width: 32px; }
.social-icon.stumble > .inactive { background-position: 0 -320px; height: 32px; width: 32px; }
.social-icon.skype { background-position: 0 -288px; height: 32px; width: 32px; }
.social-icon.skype > .inactive { background-position: 0 -288px; height: 32px; width: 32px; }
.social-icon.tumblr { background-position: 0 -352px; height: 32px; width: 32px; }
.social-icon.tumblr > .inactive { background-position: 0 -352px; height: 32px; width: 32px; }
.social-icon.rss { background-position: 0 -256px; height: 32px; width: 32px; }
.social-icon.rss > .inactive { background-position: 0 -256px; height: 32px; width: 32px; }
.social-icon.pinterest { background-position: 0 -224px; height: 32px; width: 32px; }
.social-icon.pinterest > .inactive { background-position: 0 -224px; height: 32px; width: 32px; }
.social-icon.linkedin { background-position: 0 -160px; height: 32px; width: 32px; }
.social-icon.linkedin > .inactive { background-position: 0 -160px; height: 32px; width: 32px; }
.social-icon.google { background-position: 0 -96px; height: 32px; width: 32px; }
.social-icon.google > .inactive { background-position: 0 -96px; height: 32px; width: 32px; }
.social-icon.vimeo { background-position: 0 -416px; height: 32px; width: 32px; }
.social-icon.vimeo > .inactive { background-position: 0 -416px; height: 32px; width: 32px; }
.social-icon.mail { background-position: 0 -192px; height: 32px; width: 32px; }
.social-icon.mail > .inactive { background-position: 0 -192px; height: 32px; width: 32px; }
.social-icon.favorites { background-position: 0 -32px; height: 32px; width: 32px; }
.social-icon.favorites > .inactive { background-position: 0 -32px; height: 32px; width: 32px; }
.social-icon.flickr { background-position: 0 -64px; height: 32px; width: 32px; }
.social-icon.flickr > .inactive { background-position: 0 -64px; height: 32px; width: 32px; }
.social-icon.instagram { background-position: 0 -128px; height: 32px; width: 32px; }
.social-icon.instagram > .inactive { background-position: 0 -128px; height: 32px; width: 32px; }
.social-icon.yelp { background-position: 0 -448px; height: 32px; width: 32px; }
.social-icon.yelp > .inactive { background-position: 0 -448px; height: 32px; width: 32px; }
